.navbar-custom {
    position: relative;
    top: 0;
    padding: 0;
    display: inline-flex;
    width: 100%;
    height: 4rem;
    background-color: rgba(0,0,0,.5);
    backdrop-filter: blur(10px);
    box-shadow: 0px 5px 10px rgba(0,0,0,0.5);
    z-index: 2;
}

.navbar-custom ul {
    display: inline-flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    list-style-type: none;
    margin: 0;
}

.navbar-custom .nav-base {
    display: inline-flex;
    align-items: center;
    width: calc(100% / 3);
    height: 100%;
    padding: 0px 20px;
    pointer-events: visible;
}

.navbar-custom .nav-left {
    justify-content: flex-start;
}

.navbar-custom .nav-right {
    justify-content: flex-end;
}

.navbar-custom .nav-center {
    justify-content: center;
}

.navbar-custom ul li {
    padding: 10px;
    height: 100%;
}

.navbar-custom a, .navbar-custom a:hover {
    color: #fff;
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-custom a {
    font-weight: 200;
    text-align: center;
}

.navbar-custom a:hover {
    font-weight: 500;
}