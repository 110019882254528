.login-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 4rem);
    justify-content: center;
    align-items: center;
}

.login-prompt {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    /* background: rgba(255,255,255,0.1); */
    padding: 20px;
    border-radius: 10px;
}

.login-prompt form {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-prompt .form-control::placeholder {
    color: #fff;
    text-align: center;
    font-weight: 200;
}

.login-prompt .logo {
    color: #fff;
    font-size: 60px;
    padding: 10px;
    margin-bottom: 20px;
}

.login-prompt .form-control {
    font-size: 1rem;
    color: #fff;
    background-color: rgba(255,255,255,.1);
    max-width: 200px;
    margin: 0 auto;
}

.login-prompt .submit-button {
    border-radius: 50%;
    width: 42px;
    height: 42px;
}