* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins';
}

body {
  background-color: #222;
  color: #000;
}

#map {
  height: 100%;
}

html, body {
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

#root .content {
  height: calc(100% - 4rem);
}

.form-control, .form-control:focus {
  background: rgba(0,0,0,0.1);
  color: #000;
  border: 0;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  font-size: .8rem;
}

.form-control:disabled, .form-control[readonly] {
  background: rgba(0,0,0,0.1);
  opacity: .5;
}

.form-group {
  margin-bottom: 1rem;
  color: #000;
}

@keyframes blur-ani{
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(5px);
  }
}

@keyframes rotate-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-counter-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.logo {
  font-size: 3rem;
}

.interative-start {
  cursor: pointer;
  font-size: 2rem;
  color: #FDCC0D;
}
